<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.666748 5.50002L1.60675 6.44002L5.33341 2.72002V10.8334H6.66675V2.72002L10.3867 6.44669L11.3334 5.50002L6.00008 0.166687L0.666748 5.50002Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcArrowUp',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>
